import React from "react";
import { Helmet } from "react-helmet";

const TermsNConditions = () => {
  return (
    <div className="terms-and-conditions">
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <br />
      <h1>Terms And Conditions</h1>
      <br />
      <p>
        Phnk is a platform that allows people to build communities, share their
        thoughts and ideas, express themselves creatively and connect with one
        another. These Terms govern your use of Phnk App features, apps,
        services, technologies and software that we offer except where we
        expressly state that separate terms (and not these) apply. These
        Products are provided to you by Phnk Networks India Private Limited.
      </p>
      <p>
        Downloading our app is free of cost and we don’t charge for the usage of
        the app. As Phnk is a platform offering people to host their groups,
        events, shops and consulting practice, these services may be chargeable
        with the charges prescribed by the host. Phnk has no role in determining
        these charges. Phnk may allow individuals and businesses to advertise on
        our platform and to show ads for their products and services. Phnk will
        keep these ads to the minimum and as relevant as possible.
      </p>
      <p>
        We don't sell your personal data to advertisers, and we don't share
        information that directly identifies you (such as your name, email
        address or other contact information) with advertisers unless you give
        us specific permission. We may use your age, gender and location
        identifiers in an anonymized fashion to show you content and ads that is
        most relevant to you. Similarly we may make available reports about the
        performance of their ads that help them understand how people are
        interacting with their content.
      </p>
      <p>
        To know more about how we collect, store and share your personal
        information please read our privacy policy.
      </p>
      <ol type="1">
        <li className="pp-head">Phnk Services</li>
        <p>
          Our mission is to empower individuals to build meaningful connections
          and foster a sense of community. We strive to bring people together
          and promote understanding and empathy. To support this mission, we
          offer a range of products and services that are designed to help
          individuals connect with others and engage with the world around them
          in a positive and meaningful way. Some of the services that we offer
          are:
        </p>
        <p>
          Profile creation: Users can create a personalized profile, adding
          information such as their name, location, interests, and contact
          information.
        </p>
        <p>
          Friend or follow functionality: Users can connect with others by
          adding them as friends or following their profiles. This allows them
          to see the other user’s posts and updates in their feed.
        </p>
        <p>
          Posting and sharing content: Users can create and share posts, such as
          status updates, photos, videos, and links, with their friends or
          followers.
        </p>
        <p>
          Messaging: Users can send private messages to one another, enabling
          them to communicate directly and privately.
        </p>
        <p>
          Commenting and liking: Users can comment on others’ posts and like
          them to show their appreciation or support.
        </p>
        <p>
          Tagging and mentioning: Users can tag others in their posts or mention
          them in their comments, which notifies the mentioned user and creates
          a link to their profile.
        </p>
        <p>
          Groups and communities: Users can join groups or communities based on
          shared interests or affiliations, enabling them to connect with others
          who share similar interests.
        </p>
        <p>
          Notifications: Users receive notifications when someone interacts with
          their content, such as liking, commenting, or mentioning them.
        </p>

        <li className="pp-head">How Phnk Makes money</li>
        <p>
          Phnk makes money through multiple ways, including but not limited to
          ads, subscriptions and e-commerce services for physical and virtual
          goods.
        </p>
        <p>
          <span className="pp-head-1">Ads:</span> When you use Phnk’s products
          and services, you agree to let us show you personalized ads and
          sponsored content. We use your personal data, such as your activity
          and interests, to make sure the ads and content we show you are
          relevant and useful. By using our products, you’re helping us to
          provide you with a better ad experience.
        </p>
        <p>
          <span className="pp-head-1">Paid Groups:</span> Phnk offers a variety
          of paid subscription groups that provide access to exclusive content
          from experts and creators. These groups are available on a monthly or
          annual basis, and they offer a range of benefits that are not
          available on non-subscription groups. By subscribing to one of these
          groups, you’ll gain access to in-depth discussions, specialized
          knowledge, and valuable insights from experienced professionals in
          their respective fields. Additionally, you’ll have the opportunity to
          connect with like-minded individuals and engage in meaningful
          discussions that can help you grow both personally and professionally.
        </p>
        <p>
          The paid groups on phnk are carefully curated to ensure that the
          content is high-quality, relevant, and engaging. The experts and
          creators who contribute to these groups are passionate about sharing
          their knowledge and expertise, and they are committed to providing
          valuable insights and advice. By subscribing to a paid group, you’ll
          be able to tap into their wealth of knowledge and experience, and gain
          a deeper understanding of the topics that interest you.
        </p>

        <li className="pp-head">
          Community Standards: What you can post and not post
        </li>
        <div className="pp-head-1">Bullying and harassment</div>

        <p>
          We take bullying and harassment very seriously, and we want to make
          sure that our platform is a safe and welcoming environment for all
          users. Bullying and harassment can have serious negative effects on
          individuals and communities, and we will not tolerate such behavior on
          our platform.
        </p>
        <p>
          Definition of Bullying and Harassment: Bullying and harassment
          include, but are not limited to, behavior that is intended to
          intimidate, belittle, or harm another person, or that is meant to
          frighten, threaten, or incite violence against another person. This
          can include, but is not limited to, comments, messages, posts, or any
          other form of communication that is intended to hurt, threaten, or
          degrade another person.
        </p>
        <p>
          Policy: We prohibit any behavior that constitutes bullying or
          harassment on our platform. We encourage all users to treat each other
          with respect and kindness, and to report any behavior that they
          believe violates this policy.
        </p>
        <p>
          Consequences: If we determine that a user has engaged in bullying or
          harassment, we may take a number of steps, including but not limited
          to:
        </p>
        <ul>
          <li>Issuing a warning to the user</li>
          <li>Suspending the user’s account</li>
          <li>Terminating the user’s account</li>
          <li>
            Reporting the behavior to law enforcement or other appropriate
            authorities
          </li>
        </ul>
        <p>
          We reserve the right to take appropriate action to protect the safety
          and well-being of our users, and we will not tolerate any behavior
          that undermines the safety and respect of our community.
        </p>
        <p>
          We encourage all users to report any behavior that they believe
          violates this policy. If you believe that you or someone else is being
          bullied or harassed, please let us know so that we can take
          appropriate action.
        </p>
        <p>
          We are committed to creating a safe and welcoming environment for all
          users, and we will do everything in our power to prevent bullying and
          harassment on our platform. Thank you for helping us create a positive
          and respectful community.
        </p>

        <div className="pp-head-1">Abusive language</div>
        <p>
          We are dedicated to fostering a safe and welcoming atmosphere for all
          users on our platform. Any language that we deem to be abusive or
          harmful is not allowed. Abusive language includes, but is not limited
          to, threats, hate speech, personal attacks, insults, and harassment.
        </p>
        <p>
          We use a combination of algorithms and human moderators to monitor and
          enforce this policy. We are committed to creating a welcoming
          environment for all users and take any violations of this policy
          seriously. This policy is a part of our larger effort to make the
          world a better place.
        </p>
        <p>
          Users who violate this policy risk having their comments or posts
          removed, being temporarily or permanently banned from the platform, or
          receiving a warning. We encourage users to report any abusive language
          to us, and we reserve the right to remove any content that, in our
          judgment, violates this policy.
        </p>

        <div className="pp-head-1">Hate Speech</div>

        <p>
          We are committed to creating a safe and welcoming environment for all
          users, and we prohibit hate speech in any form on our platform. Hate
          speech is any content that promotes or condones violence against, or
          hatred towards, individuals or groups based on race, ethnicity,
          gender, religion, sexual orientation, disability, or any other
          protected characteristic. This includes symbols, imagery, or
          propaganda that is intended to promote or glorify hate groups or
          ideologies.
        </p>
        <p>
          We take all reports of hate speech seriously and will take appropriate
          action, up to and including removal of the content and suspension or
          termination of the account, when we determine that hate speech has
          occurred.
        </p>
        <p>
          We understand that there may be content that is offensive or
          controversial, but that does not necessarily rise to the level of hate
          speech. In these cases, we may not remove the content, but we may
          restrict its visibility or provide additional context to help users
          understand the content in a broader perspective.
        </p>
        <p>
          We encourage users to report any content that they believe violates
          this policy, and we will review all reports promptly and take
          appropriate action.
        </p>
        <p>
          We are committed to fostering a culture of respect, inclusion, and
          empathy on our platform, and we will not tolerate hate speech in any
          form.
        </p>

        <div className="pp-head-1">Graphic violence</div>

        <p>
          Graphic violence refers to any content that depicts violent or gory
          acts, including but not limited to, murder, torture, mutilation, or
          suicide. This includes images, videos, or descriptions that are
          intended to shock or disturb, and that may cause harm to individuals
          or groups.
        </p>
        <p>
          We prohibit any content that promotes or glorifies graphic violence.
          This includes, but is not limited to, content that:
        </p>
        <ul type="bullet">
          <li>
            Depicts violent or gory acts, including images or videos of
            injuries, death, or violence against animals or humans.
          </li>
          <li>
            Threatens or promotes violence against individuals or groups based
            on race, ethnicity, gender, religion, or any other characteristic.
          </li>
          <li>
            Provides instructions or encourages engagement in violent or harmful
            activities.
          </li>
        </ul>
        <p>
          We will remove and report any content that violates this policy. We
          also reserve the right to suspend or terminate the account of any user
          who violates this policy.
        </p>
        <p>
          We understand that graphic violence may be necessary in certain
          contexts, such as educational or documentary purposes. Therefore, we
          may allow exceptions to this policy for content that:
        </p>
        <ul>
          <li>
            Serves an educational or documentary purpose and is presented in a
            respectful and appropriate manner.
          </li>
          <li>
            Is related to a newsworthy event and is presented in a manner that
            is respectful and not gratuitous.
          </li>
        </ul>
        <div className="pp-head-1">Nudity</div>

        <p>
          Posting images of genitalia of both men and women in public accounts
          and public groups is strictly prohibited. You may post these images in
          your private accounts and private groups which are restricted to users
          of ages 18 and above. Images depicting nudity in public groups would
          be taken down and accounts suspended or deleted.
        </p>

        <p>
          These guidelines need to followed while posting nude images and
          graphics or videos of sexual acts.
        </p>

        <ul>
          <li>All such acts need to be consensual.</li>
          <li>
            All the images and videos posted should be with the consent of the
            individuals.
          </li>
          <li>
            You may post fully nude pictures which we may tag as sensitive
            content making the potential viewers aware of the content in the
            images.
          </li>
          <li>
            You may post images and videos of sexual acts which are consensual
            in nature and whose rights belong to you.
          </li>
        </ul>

        <div className="pp-head-1">Spam</div>

        <p>
          Spam refers to any content or activity that is unsolicited, unwanted,
          and repetitive, and that disrupts the normal functioning of the
          platform. This includes, but is not limited to, unsolicited messages,
          comments, posts, likes, shares, and followers.
        </p>
        <p>
          There are several types of spam that are prohibited on the platform,
          including:
        </p>
        <ul>
          <li>Unsolicited commercial messages or advertisements</li>
          <li>Unsolicited personal messages or requests</li>
          <li>Posts or comments that are off-topic or disruptive</li>
          <li>
            Posts or comments that are inappropriate, offensive, or harmful
          </li>
          <li>Posts or comments that impersonate other users or entities</li>
          <li>Posts or comments that violate the privacy of other users</li>
          <li>
            Posts or comments that promote or facilitate illegal activities
          </li>
          <li>
            Posts or comments that contain malware, viruses, or other harmful
            software
          </li>
        </ul>
        <p>
          The platform will use a combination of human moderators and automated
          tools to enforce this policy. Users who violate this policy may have
          their content removed, their accounts suspended or terminated, and/or
          be subject to other penalties, as determined by the platform in its
          sole discretion.
        </p>
        <p>
          Users can report spam by using the reporting tools provided on the
          platform. The platform will review all reports of spam and take
          appropriate action.
        </p>
        <p>
          Users are responsible for ensuring that their content and activities
          comply with this policy. Users who violate this policy may have their
          accounts suspended or terminated, and/or be subject to other
          penalties, as determined by the platform in its sole discretion.
        </p>

        <div className="pp-head-1">Self Harm & Suicide</div>
        <p>
          Content that promotes, encourages, coordinates or provides
          instructions for
        </p>
        <ul>
          <li>Suicide</li>
          <li>Self-injury.</li>
          <li>Eating disorders.</li>
        </ul>
        <p>Content that depicts graphic self-injury imagery</p>
        <p>
          It is against our policies to post content depicting a person who
          engaged in a suicide attempt or death by suicide. Any such content
          would be taken down immediately.
        </p>

        <div className="pp-head-1">Incitement to self harm</div>
        <p>
          We are committed to creating a safe and supportive online community
          where all users can feel welcome and respected. We encourage users to
          report any content that promotes or glorifies self-harm or other
          dangerous behaviors. Our team will review all reports and take
          appropriate action to ensure that our platform remains a positive and
          supportive environment for all.
        </p>
        <p>
          We also recognize that mental health is an important issue that
          affects many people, and we want to support our users in any way we
          can. If you are struggling with mental health issues, we encourage you
          to reach out to a trusted friend, family member, or mental health
          professional for support. There are also many resources available
          online that can provide help and guidance.
        </p>
        <p>
          Remember, we are all here to support each other and promote positive
          and healthy online interactions. Let’s work together to create a safe
          and respectful community for everyone.
        </p>

        <div className="pp-head-1">Child abuse and exploitation</div>
        <p>
          Phnk takes the issue of child sexual exploitation very seriously and
          has strict policies in place to prevent and report this type of
          content. Child sexual exploitation includes any material that depicts
          or suggests sexual activity involving a child under the age of 18.
          This includes both real-life images and illustrations or
          computer-generated images. Sharing, viewing, or linking to this
          content is not allowed on Phnk and can be considered a violation of
          our rules.
        </p>
        <p>
          Content depicting or promoting child sexual exploitation is strictly
          prohibited. This includes visual depictions of children engaging in
          explicit or suggestive acts, as well as sexualized comments about
          minors or links to such material. Additionally, sharing or promoting
          fantasies involving child exploitation, soliciting explicit materials
          involving minors, or engaging in inappropriate conversations with
          children is not allowed. Identifying victims of childhood sexual
          exploitation or normalizing attraction to minors as a sexual
          orientation is also prohibited. These behaviors are strictly enforced
          to ensure the safety and protection of minors online.
        </p>
        <p>
          Child sexual exploitation is a form of child abuse and is illegal in
          all countries. It is also a crime to promote or glorify child sexual
          exploitation, which includes sharing or linking to content that
          furthers this behavior. Any such content would be reported to
          authorities for legal action.
        </p>
        <p>
          If you see any content on Phnk that you believe to be child sexual
          exploitation, please report it immediately to us. We have a dedicated
          team that reviews reports and takes action as necessary.
        </p>

        <div className="pp-head-1">Political Content</div>

        <p>
          We strongly discourage political discussions on Phnk, creation of
          groups to discuss political parties and/or political personalities is
          prohibited. All such groups and content would be deleted by Phnk
          immediately without any warning or notice.
        </p>

        <div className="pp-head-1">
          Promoting restricted products and services
        </div>

        <p>
          Posts about promoting, buying, selling, gifting, donating, soliciting
          or hosting consults and hosting events with respect to the following
          products and services is strictly prohibited by anyone.
        </p>

        <ol type="1">
          <li>Firearms, ammunition, parts of firearms, explosives.</li>
          <li>
            Non-pharmaceutical drugs, prohibited drugs and contraband drugs and
            narcotics.
          </li>
          <li>Marijuana and related products</li>
          <li>Tobacco and tobacco related products</li>
          <li>
            Alcohol and alcohol related products, except by verified accounts,
            the visibility of the same would be restricted to account holders of
            ages 18 and above.
          </li>
          <li>
            Prescription pharmaceutical drugs, except by verified accounts that
            are categorized under medical professionals, the visibility of the
            same would be restricted to account holders of ages 18 and above.
          </li>
          <li>Endangered wildlife species and plants</li>
          <li>
            Weight loss products and services, except by verified accounts that
            are categorized under medical professionals, the visibility of the
            same would be restricted to account holders of ages 18 and above.
          </li>
        </ol>

        <div className="pp-head-1">Promoting crime</div>
        <p>
          In our efforts to create a safe and respectful community, we prohibit
          content that promotes or facilitates harmful or illegal activities.
          This includes:
        </p>
        <p>Harm against people:</p>
        <ul>
          <li>
            Outing individuals who may be at risk of harm, including those who
            are members of marginalized groups or have relationships with such
            individuals.
          </li>
          <li>Promoting or encouraging dangerous viral challenges.</li>
          <li>
            Advocating for or coordinating harmful activities, such as swatting
            or physical harm against animals.
          </li>
        </ul>
        <p>Harm against animals:</p>
        <ul>
          <li>
            Coordinating or promoting acts of physical harm against animals,
            except in cases of redemption, hunting, religious sacrifice, food
            preparation, pest control, mercy killing, self-defense, or
            bullfighting.
          </li>
          <li>Promoting staged animal vs animal fights.</li>
          <li>Sharing video imagery of fake animal rescues.</li>
        </ul>
        <p>Harm against property:</p>
        <ul>
          <li>
            Advocating for or coordinating harm against property, including
            vandalism, hacking with malicious intent, theft, or unauthorized
            access to data systems.
          </li>
          <li>
            We believe in promoting respect, safety, and positive interactions
            within our community. Therefore, we do not allow content that
            advocates for or promotes harmful or illegal activities. Instead, we
            encourage our members to engage in respectful and constructive
            dialogue, with the aim of fostering a positive and supportive
            community.
          </li>
        </ul>

        <div className="pp-head-1">Impersonation</div>

        <p>
          Users of Phnk are strictly prohibited from engaging in any form of
          impersonation. This includes creating accounts or profiles that
          falsely represent another individual, entity, or organization.
        </p>
        <p>
          Any attempt to misrepresent one's identity, affiliations, or personal
          details will result in immediate account suspension or termination.
        </p>
        <p>
          Impersonation may lead to legal consequences as per applicable laws
          and regulations. Our company reserves the right to cooperate with law
          enforcement authorities and take appropriate legal action against
          those found engaged in impersonation.
        </p>

        <li className="pp-head">Expert Verification</li>
        <p>
          This policy statement outlines a thorough process for verifying
          experts, ensuring that only those with the necessary qualifications
          and standing in their field are recognised as experts. By emphasising
          degree of specialisation, experience, and standing in the field, the
          policy statement sets a high bar for expertise, while also ensuring
          that the verification process is fair and transparent.
        </p>
        <p>
          To qualify as an expert, an individual must meet at least one of the
          following criteria:
        </p>
        <ol type="1">
          <li>
            Degree of specialisation: The individual must hold a degree in a
            relevant field, such as a PhD, Master’s, or Bachelor’s degree, from
            an accredited institution.
          </li>
          <li>
            Experience: The individual must have a minimum of 5 years of
            experience in their field, with a proven track record of success and
            achievement.
          </li>
          <li>
            Standing in the field: The individual must have a recognised
            standing in their field, such as being widely cited, published, or
            recognised by their peers for their contributions.
          </li>
        </ol>
        <p>
          We conduct verification on the individual’s background, including
          their education, experience, and standing in their field.
        </p>
        <p>
          Once we have completed the verification process, we grant the
          individual expert status and list them as a qualified expert in our
          database. We regularly review and update our list of experts to ensure
          that only those who continue to meet our criteria are included.
        </p>
        <p>
          By following this rigorous process, we ensure that our users can rely
          on the expertise and credibility of the individuals we recognise as
          experts.
        </p>

        <li className="pp-head">Intellectual Property and Copyright</li>
        <p>
          Users retain ownership of the original content they upload, post, or
          share on our social media platform. By agreeing to these terms, users
          grant our company a non-exclusive, worldwide, royalty-free license to
          use, reproduce, modify, adapt, publish, translate, and distribute
          their content for the purpose of operating, promoting, and improving
          our platform. This license terminates when users delete their content
          from the platform, except for content shared with others, which may
          remain visible even after removal.
        </p>
        <p>
          All usernames are property of Phnk and maybe removed due to prolonged
          non use of the account and assigned to other users at the discretion
          of Phnk. However any active account (an account that has posted at
          least once in the past six months) is safe from deletion and/or
          reassignment.
        </p>
        <p>
          Phnk respects copyright laws and expects users to do the same. Users
          must not upload, post, or share content that infringes on the
          intellectual property rights of others, including but not limited to
          copyrighted material, trademarks, or trade secrets.
        </p>
        <p>
          We have a process in place to handle copyright infringement claims. If
          users believe their copyrighted material has been unlawfully used on
          our platform, they can submit a valid copyright infringement notice,
          and we will promptly investigate and take appropriate action, which
          may include removing the infringing content or disabling access to it.
          A mail can be sent to support@phnk.in for any copyright infringement.
        </p>
        <p>
          Users are solely responsible for ensuring they have the right to share
          the content they post on our platform. Any unauthorized use of
          copyrighted material may result in legal consequences, including
          liability for damages and legal fees.
        </p>
        <p>
          Intellectual Property of the Company: All proprietary content,
          software, design, graphics, logos, trademarks, and other intellectual
          property associated with Phnk are the exclusive property of our
          company and are protected by applicable intellectual property laws.
        </p>
        <p>
          Users are not permitted to use Phnk’s intellectual property without
          explicit permission, except when sharing content from our platform in
          compliance with our terms of use.
        </p>
        <p>
          Respect for Others' Intellectual Property: We encourage our users to
          respect the intellectual property of others and report any suspected
          copyright violations they encounter on our platform.
        </p>
        <p>
          Termination of Infringing Accounts: Our company reserves the right to
          terminate the accounts of users who repeatedly infringe on the
          intellectual property rights of others or violate our copyright
          material clause.
        </p>
        <li className="pp-head">Account Suspensions, termination and appeal</li>
        <p>
          We want Phnk to be a place where people can freely express themselves
          and share their ideas without any hate or judgment.
        </p>
        <p>
          If we determine, in our sole discretion, that you have clearly,
          seriously, or repeatedly violated our Terms or Policies, including, in
          particular, the Community Standards, we may suspend or permanently
          disable your access to Phnk, as well as suspend or permanently delete
          your account. We may also disable or delete your account if you
          repeatedly infringe on the intellectual property rights of others, or
          if we are required to do so by law.
        </p>
        <p>
          We may disable or delete your account if, if your account is unused
          and remains inactive for an extended period of time, or we suspect
          that someone used it without your permission and we are unable to
          confirm your ownership of the account.
        </p>
        <p>
          If we take such action, we will notify you and explain your options
          for requesting a review, unless doing so would expose us or others to
          legal liability; harm our user community; compromise or interfere with
          the integrity or operation of any of our services, systems, or
          Products; be restricted due to technical limitations; or be
          prohibited.
        </p>
        <p>
          If your account has been disabled you can appeal to us and provide us
          information that may be required to restore your account. You can send
          a mail to support@phnk.in to do so.
        </p>
        <p>
          Account creation on Phnk does not require you to enter and confirm
          your email or phone number. In a scenario where you have not entered
          your email or phone number, account recovery is difficult. It is
          advised that you keep your password secure and not share it with
          anyone.
        </p>
        <p>
          Phnk is not liable for any downtime our app can experience while we
          ensure the best products possible while also establishing clear
          guidelines for everyone who uses them. Our Products, on the other
          hand, are provided "as is," and we make no guarantees that they will
          always be safe, secure, or error-free, or that they will function
          without interruptions, delays, or imperfections, to the extent
          permitted by law. We also disclaim all warranties, express or implied,
          to the extent permitted by law, including the implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement. We do not control or direct what people do or say,
          and we are not liable for their actions or conduct (whether online or
          offline) or any content they share (including offensive,
          inappropriate, obscene, unlawful, or otherwise objectionable content).
        </p>
        <p>
          We make every effort to Phnk is a safe space for everyone, however our
          moderation tools and manpower are limited and we encourage users to
          make sure to mark any offensive, disrespectful, abusive post to make
          Phnk a safe space for all users.
        </p>
        <p>
          We make every effort to keep the Phnk platform free of spammers and
          fraudulent users, limiting the creation of shops, events and consults
          on the platform to only the verified accounts. Users are advised to
          due diligence and exercise caution while dealing with other users they
          meet on the site. You are advised to not exchange personal information
          such as name, phone number, email address, other social media account
          information, physical address etc with each other. Phnk will not be
          liable for any personal loss or injury that may result from off
          platform transactions or interactions.
        </p>

        <li className="pp-head">Disputes and Settlement</li>
        <p>
          The site is governed by the IT laws of India and In case of any
          dispute the applicable court of law would be the courts of Delhi,
          India.{" "}
        </p>
        <li className="pp-head">Refund and cancellation policy</li>
        <ol type="a">
          <li>A.I. generated avatar is non-refundable.</li>
          <li>
            All subscription are non-refundable, if they are canceled while the
            subscription is valid there would no refund of the balance amount.
            The subscription needs to be canceled at least two days before the
            subscription renewal date. Upon cancellation there would be no
            renewal.
          </li>
          <li>
            All chat and talk with experts are non-refundable. However if the
            customer feels that the advice is fraudulent or is a case of
            impersonation, they can send an email to{" "}
            <a style={{color: "blue", textDecorationLine: "underline"}} href="mailto:hi@phnk.in">hi@phnk.in</a> and our team would review
            the recordings and determine if the concern is genuine. Based on
            that a refund would be issued.
          </li>
          <li>
            Any money left in wallet can be refunded back just send a mail to{" "}
            <a style={{color: "blue", textDecorationLine: "underline"}} href="mailto:hi@phnk.in">hi@phnk.in</a> and request a refund of
            the balance amount.
          </li>
        </ol>
      </ol>
    </div>
  );
};

export default TermsNConditions;
