import React from 'react'
import '../style/getApp.css'
// import { Button } from '@mui/material'
// import playStoreLogo from '../assets/google-play-store-icon.svg'
// import appleLogo from '../assets/apple-icon.svg'
import getAndroidApp from '../assets/download_for_android.png'
import googleBadge from '../assets/google_play_badge.svg'

const GetApp = () => {
  return (
    <div className='get-app'>
      <h1>Get the Phnk App to participate.</h1>
      <a
        className='get-app-img'
        href='https://d2ae7l5k0jeeii.cloudfront.net/apk/phnk.apk'
        download="Phnk.apk"
        target="_blank"
        rel="noreferrer"
        style={{
          marginTop: '6rem'
        }}
      >
        <img src={getAndroidApp} alt='get-android-app' onClick={() => { }} />
      </a>
      <a
        className='get-app-img'
        href='https://play.google.com/store/apps/details?id=com.phnk&pcampaignid=web_share'
        target="_blank"
        rel="noreferrer"
      >
        <img src={googleBadge} alt='google-badge' onClick={() => { }} />
      </a>
      {/* <div className='get-app-btns'>
        <Button
          variant='contained'
          style={{
            backgroundColor: '#000',
            color: '#fff',
            textTransform: 'none',
            lineHeight: '1'
          }}
        >
          <img className='get-app-logo' src={playStoreLogo} alt='playstore-logo' />
          <div>
            <div>Get it on</div>
            <div style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}>Google Play</div>
          </div>
        </Button>
        <Button
          variant='contained'
          style={{
            backgroundColor: '#000',
            color: '#fff',
            textTransform: 'none',
            lineHeight: '1'
          }}
        >
          <img className='get-app-logo' src={appleLogo} alt='apple-logo' />
          <div>
            <div>Download on the</div>
            <div style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
            }}>App Store</div>
          </div>
        </Button>
      </div> */}
    </div>
  )
}

export default GetApp