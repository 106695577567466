import React from 'react'

const JobsApply = () => {
    return (
        <div style={{
            marginTop: '10px'
        }}>
            <iframe
                title='join-as-expert'
                src="https://docs.google.com/forms/d/e/1FAIpQLSdM8ZQKrOK3L1iSb_AD1aIYG09IVYVRh8nDvmlcraqGokMRyA/viewform"
                className='google-form'
            >
                Loading…
            </iframe>
        </div>
    )
}

export default JobsApply