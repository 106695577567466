import React, { useState, useEffect } from 'react'
import '../style/trendingDis.css'
import { MessageCircle, Bookmark, Send, MoreHorizontal } from 'lucide-react'
import Axios from 'axios'
import MediaDisplay from './Trending Discussions/MediaDisplay'
import verified from '../assets/verified.jpg'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from 'react-router-dom'

const TrendingDiscussions = (props) => {
  const navigate = useNavigate()
  dayjs.extend(relativeTime)

  const [data, setData] = useState([])

  const FormattedText = ({ textWithNewlines }) => {
    const processedText = textWithNewlines ? textWithNewlines.replace(/\n/g, '<br>') : '';
    return <span dangerouslySetInnerHTML={{ __html: processedText }} />;
  };

  const Card = (cardProps) => {
    const [detailed, setDetailed] = useState(true)
    return (
      <div
        className={`card-5 ${detailed ? 'card-5-detailed' : 'card-5-short'}`}
      >
        <div className={`${detailed ? 'card-5-description' : 'none'}`}>
          <div className='card-5-sect card-5-sect-1' style={{
            display: 'flex',
            alignItems: 'center',
          }}
            onClick={() => navigate('/app')}
          >
            <img
              src={cardProps.ele.user_profile_picture}
              alt='profile'
              className='card-5-sect-1-img'
            />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <span className='bold flex-center'>
                {cardProps.ele.user_alias} {cardProps.ele.expertise_name !== null ? `(${cardProps.ele.expertise_name})` : ''}
                {cardProps.ele.user_verified == true &&
                  <img className='card-5-sect-3-icon' src={verified} alt='' style={{
                    color: 'blue',
                    margin: 'auto 5px',
                    height: '20px',
                    aspectRatio: '1/1'
                  }} />}
              </span>
              <span className={cardProps.ele.group_alias === null ? `none` : ''}>
                /{cardProps.ele.group_alias}
              </span>
            </div>
            <MoreHorizontal className='card-5-sect-1-icon left-auto' style={{
              marginLeft: 'auto',
            }} />
          </div>

          {cardProps.ele.post_media.length !== 0 &&
            <div className='card-5-sect card-5-sect-2' onClick={() => navigate('/app')}>
              <MessageCircle className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <Bookmark className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <Send className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <span className='left-auto'>{cardProps.ele.total_reply} Comments</span>
            </div>
          }
          {cardProps.ele.post_text &&
            <div className='card-5-sect card-5-sect-3'>
              {/* <span style={{
                display: 'inline-flex',
                fontWeight: 'bold',
                paddingRight: 5
              }}>
                {cardProps.ele.user_alias}
              </span> */}
              <span>
                <FormattedText textWithNewlines={cardProps.ele.post_text} />
              </span>
            </div>
          }
          {cardProps.ele.post_media.length === 0 &&
            <div className='card-5-sect card-5-sect-2' onClick={() => navigate('/app')}>
              <MessageCircle className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <Bookmark className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <Send className='card-5-sect-1-icon'
                onClick={() => navigate('/app')} />
              <span className='left-auto'>{cardProps.ele.total_reply} Comments</span>
            </div>
          }

          {/* <div
            className='highlight-1'
            onClick={() => {
              setDetailed(!detailed)
            }}>
            <u>see less</u>
          </div> */}

          <div className='card-5-sect card-5-sect-4'>
            {dayjs(cardProps.ele.posted_at).fromNow()}
          </div>
        </div>
        {/* <div className={`${!detailed ? '' : 'none'}`}>
          <div className={`card-5-text`}>
            {cardProps.ele.post_text}
          </div>

          <div
            className='highlight-1'
            onClick={() => {
              setDetailed(!detailed)
            }}>
            <u>see more</u>
          </div>
        </div> */}
        <div className='card-5-media'>
          <MediaDisplay
            media={cardProps.ele.post_media ? cardProps.ele.post_media : []}
            detailed={detailed}
            autoplay
            controls
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    Axios.get(`https://prd-api-1.phnk.in/post/get_public_timeline`, {
      headers: {
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozODA0NDAyOTAsImlhdCI6MTY5MzIyNDM5OCwiZXhwIjoxNzc5NTM3OTk4fQ.kY2CNrB4nfyN16Sh-Q2Wbkwf6O06X2Cj69UAgCECQj8`
      }
    }).then((res) => {
      setData(res.data.data.result)
      console.log(res.data.data.result)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  if (props.data.length !== 0) {
    // console.log(props.data)
    return (
      <div className='trending-discussion'>
        (searched data)
        {props.data.map((ele, index) => {
          return (<Card key={index} ele={ele} />)
        })}
      </div>
    )
  }
  return (
    <div className='trending-discussion'>
      {data.length !== 0 ? <>
        {data.map((ele, index) => {
          return (<Card key={index} ele={ele} />)
        })}
      </>
        : <></>}
    </div>
  )
}

export default TrendingDiscussions
