import { Button } from '@mui/material'
import React from 'react'

const Hackathon = () => {
    return (
        <div className='hackathon'>
            <h1>Join the Phnk Hackathon 2023</h1>
            <p>TThe theme for Phnk Hackathon 2023 is using Artificial Intelligence to create applications that are useful to women. Teams have to come up with a working application to demonstrate their idea.</p>
            <h3>Who is eligible to participate?</h3>
            <p>Girl students in college or 1 year out of college.</p>
            <h3>
                Team formation
            </h3>
            <p>Girls can form team of minimum 2 and maximum 4.</p>
            <h3>
                Prize money
            </h3>
            <p>The winning team gets Rs. 100,000, runner up 1 gets Rs. 50,000 and second runner up Rs. 25,000.</p>
            <h3>
                Important Dates
            </h3>
            <p>
                Application Submission Ends: 31st August 2023<br />
                Idea Submission: 15th September 2023<br />
                Teams Shortlisted: 22nd September 2023<br />
                Presentation: 30th September to 7th October 2023<br />
                Results: 7th October 2023<br />
            </p>
            <h3>
                More details
            </h3>
            <Button
                variant='contained'
                style={{
                    margin: '10px 0'
                }}
            >Apply</Button>
        </div>
    )
}

export default Hackathon