import React from 'react'
import manish from '../assets/manish.jpg'
import sangita from '../assets/sangita.jpg'
import amit_kumar from '../assets/amit_kumar.jpg'

const Team = () => {
    const Card = (props) => {
        return (
            <div className='card-4'>
                {/* <div className='team-image'>
                    <img src={props.src ? props.src : 'https://picsum.photos/200/300'} alt='team' />
                </div> */}
                <div>
                    <div className='member-name'>
                        {props.name}
                    </div>
                    <div className='member-about' style={{
                        marginTop: '10px'
                    }}>
                        <img style={{
                            // display: 'inline-block',
                            float: 'left',
                            margin: '0 10px 10px 0',
                        }} src={props.src ? props.src : 'https://picsum.photos/200/300'} alt='team' />

                        {props.about}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='team-box'>
            <Card
                name='Manish Sharma, Founder and CEO'
                about='Manish is a graduate from IIT BHU, passionate about creating new products and experiences that help people lead more fulfilling lives. Over the last 25 years, he has created a rich body of work, from working with AI/ML as early as 1997, to working with IBM and Microsoft and its customers on extremely large and complex projects. He has advised heads of nations (Prime Ministers and Presidents) on how to create and manage their personas amongst masses. At Phnk, Manish, acts as a mentor and guide its young and enthusiastic team.'
                src={manish}
            />
            <Card
                name='Sangita Gopinath - Co-founder'
                about='Sangita is a communication specialist who excels in strategic planning, execution, and showcasing cutting-edge products and services. She has a proven track record of converting business requirements into meaningful messaging for corporate development. With a deep understanding of internal motivational drivers, she facilitates effective employee communication and cross-functional and cross-cultural engagements. She have worked with senior management teams across geographies, industries, channel partners, government, and media. Her experience in start-ups has also honed their skills in customer care, product management, and operations.'
                src={sangita}
            />
            <Card
                name='Amit Kumar, Co-founder'
                about='Amit is a highly skilled software developer with a Bachelor’s degree in Computer Science and two years of work experience in the field. He has a strong passion for software development and is enthusiastic about creating innovative solutions that make a positive impact. Amit also enjoys exploring new foods and cuisines in his free time. As the head of the software development team at Phnk, Amit is responsible for leading the team in building cutting-edge software that aligns with the company’s vision and goals. He is dedicated to fostering a collaborative and inclusive work environment that encourages creativity, innovation, and professional growth.'
                src={amit_kumar}
            />
        </div>
    )
}

export default Team