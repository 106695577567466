import React from 'react'

const JoinAsExpert = () => {
    return (
        <div>
            <iframe
                title='join-as-expert'
                src="https://docs.google.com/forms/d/e/1FAIpQLSea8fUQ5lP7daSWz0cxzX48NzOt8GqkkkXYlQ0F4_UVS7ZRtg/viewform?embedded=true"
                className='google-form'
                // width="640"
                // height="550"
                // frameBorder="0"
                // marginHeight="0"
                // marginWidth="0"
            >
                Loading…
            </iframe>
        </div>
    )
}

export default JoinAsExpert