import React, { useState } from 'react'
import '../style/home1.css'
import playButton from '../assets/playButton1.svg'
import { Search } from 'lucide-react'
import CommunityCategory from './Home1/CommunityCategory'
import TrendingDiscussions from './TrendingDiscussions'
import Axios from 'axios'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const Home1 = () => {
  const { id } = useParams();
  const [metaData, setMetaData] = useState({ title: 'Phnk: Friends, Connections, Fun Conversations!', description: '' });
  const [selected, setSelected] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchData, setSearchData] = useState([])
  console.log(id)

  const searchIt = () => {
    Axios.get(`https://prd-api-1.phnk.in/post/search_timeline?search=${searchTerm}`, {
      headers: {
        'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTI3NTc0NjIsImlhdCI6MTY5MTY3NjI1NCwiZXhwIjoxNzc3OTg5ODU0fQ.1FsOmTA6SOvGETNPS-XOwwiPwIy2QdG_RgNnqmuuNag`
      }
    })
      .then((res) => {
        setSearchData(res.data.data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        {/* Add more meta tags as needed */}
      </Helmet><div className='home-1'>
        <div className='home-1-head'>
          Phnk: Friends, Connections, Fun Conversations!
          {/* <img src={playButton} alt='play-button' className='play-button' /> */}
        </div>
        <div className='input-box-1'>
          <Search size='1.25rem' style={{
            padding: '0.5rem',
          }} />
          <input
            type='text'
            className='home-1-search'
            
            placeholder='Search categories, communities, topics'
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                searchIt()
              }
            }}
          />
        </div>

        <div className='home-1-box-1'>
          <div
            className={`home-1-box-1-ele ${selected === 0 ? 'selected' : ''}`}
            onClick={() => setSelected(0)}
          >
            Trending Discussions
          </div>
          <div
            className={`home-1-box-1-ele ${selected === 1 ? 'selected' : ''}`}
            onClick={() => setSelected(1)}
          >
            Communities
          </div>
        </div>

        <div className='home-1-screen' style={{
          position: 'relative',
        }}>
          <div
            className='trend-comm-screen'
            style={{
              paddingLeft: '1rem'

            }}>
            {selected === 0 ?
              <TrendingDiscussions
                data={searchData}
              /> : <CommunityCategory />}
          </div>
        </div>

      </div></div>
  )
}

export default Home1