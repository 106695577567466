import React from 'react'
import '../style/textContent.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const JoinIdeaHacka = () => {
  const navigate = useNavigate()
  const navigateTo = (path) => {
    navigate(path)
  }

  return (
    <div className='join-idea-hacka'>
      <h1>Join the ultimate hackathon: innovate, compete, and conquer!</h1>
      <p>Welcome to our exhilarating hackathon, where brilliant minds unite to tackle challenges, push boundaries, and shape the future of technology.</p>
      <div className='flex-center'
        style={{
          gap: '1rem',
          justifyContent: 'flex-start'
        }}>
        <Button
          variant='contained'
          style={{
            backgroundColor: '#B200ED',
          }}
          onClick={() => navigateTo('/ideathon')}
        >Ideathon</Button>
        <Button
          variant='contained'
          onClick={() => navigateTo('/hackathon')}
        >Hackathon</Button>
      </div>
    </div>
  )
}

export default JoinIdeaHacka