import { Button } from '@mui/material'
import React from 'react'
import campusAmbassador from '../assets/campus_ambassador.jpg'
import content_management from '../assets/content_management.jpg'
import influencerRelation from '../assets/influencerRelationshipManager.jpg'
import specialistRelation from '../assets/specialistRelationshipManager.jpg'
import brandAlliance from '../assets/brand_alliance.jpg'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Jobs = () => {
    const navigate = useNavigate()

    const data = [
        {
            title: 'Campus Ambassador',
            about: 'As a Campus Ambassador, you will be the face and voice of our organization on your college campus. Your main responsibility will be to promote and raise awareness about our brand, products, and services among your fellow students. You will leverage your strong communication and networking skills to organize events, engage in social media campaigns, and foster a positive brand image. By actively participating in various activities and initiatives, you will drive student interest and be the go-to person for queries related to our organization. This role offers a unique opportunity to gain practical marketing experience, build valuable connections, and earn exciting rewards and incentives.',
            link: campusAmbassador
        },
        {
            title: 'Content Strategist',
            about: `As a Content Strategist, you will be responsible for developing and implementing content plans that align with our company's goals and target audience. Leveraging your expertise in content marketing and virality, you'll conduct thorough research to identify relevant topics and industry trends. Collaborating with cross-functional teams, you'll create comprehensive content calendars, ensuring a consistent and cohesive brand message across all platforms. Your role will involve analyzing content performance metrics and making data-driven decisions to optimize content for maximum impact. Adept at storytelling and audience segmentation, you'll play a key role in driving brand awareness, engagement, and conversion through strategic and compelling content initiatives.`,
            link: content_management
        },
        {
            title: 'Influencer Relationship Manager',
            about: `As an Influencer Relationships Specialist, you will be instrumental in cultivating and managing relationships with key influencers and content creators. Your primary focus will be to identify and engage with influential individuals across various social media platforms and industries. Leveraging your excellent communication and negotiation skills, you'll collaborate with influencers to create authentic and impactful partnerships that align with our brand values and marketing objectives. Tracking and analyzing campaign performance metrics, you'll continuously optimize and measure the success of influencer collaborations. This role requires a deep understanding of social media trends, strong relationship-building abilities, and a passion for leveraging influencer marketing to drive brand visibility and engagement.`,
            link: influencerRelation
        },
        {
            title: 'Specialist Relationship Manager',
            about: `As a Specialist Relationships Coordinator, you will be responsible for fostering and nurturing strategic partnerships with industry experts, organizations, and specialists. Your key role will involve identifying potential partners, initiating contact, and establishing mutually beneficial collaborations. You'll work closely with internal teams to understand business objectives and align partnership efforts accordingly. With excellent communication and negotiation skills, you'll develop and manage contractual agreements, ensuring compliance and successful execution of projects. Your ability to build and maintain strong professional relationships will be essential in driving growth opportunities, expanding our network, and enhancing our reputation as a trusted industry leader. This role demands a proactive approach, a keen eye for opportunities, and a passion for building meaningful connections.`,
            link: specialistRelation
        },
        {
            title: 'Brand Alliance Manager',
            about: `As a Brand Alliance Manager, you will be instrumental in establishing and managing strategic partnerships with external brands and organizations. Your primary focus will be to identify potential alliance opportunities that align with our brand values and business objectives. Leveraging your exceptional communication and negotiation skills, you'll collaborate with partner brands to create innovative co-marketing campaigns and initiatives that amplify both brand's visibility and reach. You'll also work closely with cross-functional teams to ensure seamless execution of joint marketing efforts and monitor campaign performance. Your ability to build strong relationships and your passion for fostering brand collaborations will be vital in driving brand growth and market expansion.`,
            link: brandAlliance
        },
    ]

    const Card = (props) => {
        return (
            <div className='card-4'>
                <Helmet>
                    <title>Jobs at Phnk</title>
                </Helmet>
                {/* <div className='team-image'>
                    <img style={{
                        // display: 'inline-block',
                        float: 'left',
                        margin: '0 10px 10px 0',
                    }} src={props.link ? props.link : 'https://picsum.photos/200/300'} alt='team' />
                </div> */}
                <div>
                    <div className='member-name'>
                        {props.name}
                    </div>
                    <div className='member-about'>
                        <img
                            style={{
                                // display: 'inline-block',
                                float: 'left',
                                margin: '0 10px 10px 0',
                            }}
                            src={props.link ? props.link : 'https://picsum.photos/200/300'} alt='team'
                        />
                        {props.about}
                    </div>
                    <div className='flex-center' style={{
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            className='apply-btn left-auto'
                            style={{
                                backgroundColor: '#3c32a8',
                                margin: '1rem',
                                borderRadius: '25px',
                                padding: '0.5rem 2rem'
                            }}
                            onClick={navigateToJobsApply}
                        >Apply</Button>
                    </div>
                </div>
            </div>
        )
    }

    const navigateToJobsApply = () => {
        navigate('/jobsapply')
    }
    return (
        <div className='job' style={{ paddingTop: 40 }}>
            <div className='title-1' style={{
                fontSize: '24px',
                fontWeight: 'bold',
            }}>
                Jobs at Phnk
            </div>
            {data.map((item, index) => {
                return (
                    <Card
                        name={item.title}
                        about={item.about}
                        link={item.link}
                        key={index}
                    />
                )
            })}
        </div>
    )
}

export default Jobs

// Remove Sub Category
// Add Get app button
// Hamberger text aligned not properly
//logo should be aligned with text below