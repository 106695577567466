import React from "react";
import "./index.css";
import App from "./App";
import { createRoot, hydrateRoot } from "react-dom/client";
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  createRoot(rootElement).render(<App />);
}
