import React, { useState, useEffect } from 'react'
import '../../style/community.css'
import Axios from 'axios'
// import Community from './Community'
import community_icon from '../../assets/community_icon.svg'
import verified from '../../assets/verified.jpg'
import { Button } from '@mui/material'

const CommunityCategory = (props) => {
    const [data, setData] = useState([])
    const [communityList, setCommunityList] = useState([])
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        Axios.get(`https://prd-api-1.phnk.in/group/get_categories`, {
            headers: {
                'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTI3NTc0NjIsImlhdCI6MTY5MTY3NjI1NCwiZXhwIjoxNzc3OTg5ODU0fQ.1FsOmTA6SOvGETNPS-XOwwiPwIy2QdG_RgNnqmuuNag`
            }
        })
            .then((res) => {
                setData(res.data.data.result)
                getCommunityDetails(res.data.data.result[0].id)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const getCommunityDetails = (id) => {
        Axios.get(`https://prd-api-1.phnk.in/group/get_communityV1?category_id=${id}`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTI3NTc0NjIsImlhdCI6MTY5MTY3NjI1NCwiZXhwIjoxNzc3OTg5ODU0fQ.1FsOmTA6SOvGETNPS-XOwwiPwIy2QdG_RgNnqmuuNag`
            }
        })
            .then((res) => {
                setCommunityList(res.data.data.result)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const Card = (a) => {
        return (<div
            className={`category-card ${a.index === selected ? 'category-card-selected' : ''}`}
            onClick={() => {
                getCommunityDetails(a.details.id)
                console.log(communityList)
                setSelected(a.index)
                // props.setScreen(1)
            }}
        >
            <img src={a.details.images} alt='community' className='card-3-image' />
            {a.details.name}
        </div>)
    }

    const CommunityCard = (a) => {
        return (
            <div className={`card-3 flex-center`}>
                <img src={community_icon} alt='community_icon' style={{ height: '40px' }} />
                <div>
                    <div className='flex-center' style={{
                        fontWeight: 'bold',
                    }}>
                        {a.details.name}
                        <img className='card-5-sect-3-icon' src={verified} alt='' style={{
                            color: 'blue',
                            margin: 'auto 5px',
                            height: '20px',
                            aspectRatio: '1/1',
                            display: 'inline-block',
                        }} />
                    </div>
                    <div className='sm-1'>
                        {a.details.total_members} Members
                    </div>
                    <div className='sm-1'>
                        {a.details.total_experts} Experts
                    </div>
                </div>
                <Button
                    variant='contained'
                    style={{
                        borderRadius: '10px',
                        padding: '0.25rem 0.5rem',
                        marginLeft: 'auto',
                    }}
                    onClick={() => { window.open('https://phnk.me/app') }}
                >
                    Join
                </Button>
            </div>)
    }

    return (
        <div>
            <div className='community-category'>
                {data.map((ele, index) => {
                    return <Card key={index} index={index} details={ele} />
                })}
            </div>
            <div className='community'>
                {
                    communityList.length > 0 &&
                    communityList.map((ele, index) => {
                        return <CommunityCard key={index} index={index} details={ele} />
                    })
                }
            </div>
        </div>
    )
}

export default CommunityCategory