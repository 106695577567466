import React, { useState } from 'react'
import '../style/contact.css'
import Axios from 'axios'
import { success } from '../toasty'
import { Helmet } from 'react-helmet'

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const sendMessage = () => {
        Axios.post(`https://prd-api-1.phnk.in/send_mail`, {
            name: name,
            email: email,
            message: message
        }, {
            headers: {
                'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTI3NTc0NjIsImlhdCI6MTY5MTY3NjI1NCwiZXhwIjoxNzc3OTg5ODU0fQ.1FsOmTA6SOvGETNPS-XOwwiPwIy2QdG_RgNnqmuuNag`
            }
        })
            .then((response) => {
                success('Message Sent Successfully')
                setName('')
                setEmail('')
                setMessage('')
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className='contact'>
            <Helmet>
                    <title>Contact Phnk</title>
                </Helmet>
            {/* <div className='contact-title'>Have a question for us?</div> */}
            <div className='contact-title'>Contact Phnk</div>
            <input
                className='contact-input'
                placeholder='Enter your Name'
                value={name}
                onChange={(e) => { setName(e.target.value) }}
            />
            <input
                className='contact-input'
                placeholder='Enter your Email Address'
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
            />
            <textarea
                className='contact-input'
                placeholder='Enter your Message'
                rows={4}
                value={message}
                onChange={(e) => { setMessage(e.target.value) }}
            />
            <div className='contact-btn btn-1' onClick={sendMessage}>Send</div>
        </div>
    )
}

export default Contact