import React, { useState } from 'react'
import Team from './Team'
import '../style/about.css'
import { Helmet } from 'react-helmet'

const AboutUs = () => {
    const [screen, setScreen] = useState(0)
    const Screen = () => {
        if (screen === 0) {
            return (<>
                <p>Phnk: Friends, Fun Conversations Await!
                </p>
                <p>Friendship is the core of every relationship in life. It is the thing that keeps us connected with a person, the thing that brings us happiness, the thing that brings us peace and calm, the thing that brightens even the gloomiest of the day. When we want to describe how good things are between our blood relations, spouse and even children we say, we are just like friends.</p>
                <p>It is hard to imagine life without a friend, and even harder to find friends and to stay connected with them. We don't know why and how we become friends with someone for life, maybe because we share common interests, maybe because we have similar views on almost everything or maybe its unconditional love.
                </p>
                <p>Finding friends is becoming challenging everyday because of the amount of time we spend online, to add to that the ephemeral nature of the internet and social media makes it so much more difficult to have long meaningful connections and conversations with someone.</p>
                <p>At Phnk we have made it super easy to find friends and stay connected with them. Phnk lets you make interest based connections and chat with them one on one and in a group.</p>
                {/* <p>
                    In a world where online harassment and misinformation run rampant, Phnk offers a refreshing alternative. Phnk is world’s first A.I. driven online platform that puts women first. Our platform is designed to provide a safe and welcoming space for individuals to express themselves freely, without fear of judgment or repercussions.
                </p>
                <p>At Phnk, we understand the importance of anonymity in fostering open and honest communication. That’s why we allow our users to remain completely anonymous, using only an alias of their choice. This feature ensures that users feel comfortable sharing their thoughts and experiences without fear of backlash or judgment from others.
                </p>
                <p>
                    But anonymity is just the beginning. Phnk is also a platform for belonging, where individuals can find and connect with like-minded people who share their interests and passions. Our platform is organized into various communities, each tailored to a specific theme or interest. Whether you’re a young woman, a new mom, or someone seeking spiritual growth, we have a community for you.
                </p>
                <p>
                    Our communities are designed to be supportive and inclusive, where users can engage in meaningful discussions, share their experiences, and form lasting connections. We encourage our users to be themselves, without fear of judgment or rejection.
                </p>
                <p>
                    But Phnk isn’t just about community and anonymity; it’s also a platform for learning and growth. We’ve partnered with a diverse group of experts from various fields, including gynecologists, pediatricians, therapists, fitness experts, nutritionists, lawyers, and career counselors. These experts provide valuable insights and guidance within our communities, helping users make informed decisions and navigate life’s challenges.
                </p>
                <p>
                    While our experts offer valuable advice, we understand that every person’s situation is unique. That’s why we encourage our users to approach this advice with an open mind and consider it as a starting point for their journey. For personalized advice, we recommend consulting directly with the experts or seeking the assistance of other qualified practitioners.
                </p>
                <p>
                    At Phnk, we’re committed to providing a platform where users can confidently seek knowledge, guidance, and support. We continuously strive to expand our network of experts, enhancing the breadth and depth of expertise available within our communities.
                </p>
                <p>
                    By combining the power of community interaction with the wisdom of experts, Phnk creates a nurturing and empowering environment which empowers our users to make informed choices, facilitate personal growth, and foster a spirit of mutual support and learning within our virtual ecosystem. Together, we combat misinformation, promoting knowledge and understanding.
                </p>
                <p>
                    Join the Phnk community today and start experiencing the power of belonging, anonymity, and expert guidance. Download our app to start exploring our public groups, and discover the wealth of knowledge and support available within our communities.
                </p> */}


            </>)
        }
        else if (screen === 1) {
            return (<Team />)
        }
        // else if (screen === 2) {
        //     return (<>
        //         <p>
        //             Phnk, a forward-thinking company, has established a Women Advisory Council to better understand the challenges that women face in both real life and online environments. This council is composed of diverse and accomplished women from various fields, including medicine, therapy, law, technology, public policy, and communications.
        //         </p>
        //         <p>
        //             The council boasts an impressive roster of members, including Preeti Bakaya, Founder of Interel; Jhum Jhum Sarkar, Delhi HC Lawyer; Sangita Gopinath, Director at a leading PR agency; Dr. Shweta Shah, Gynaecologist; Dr. Himani Verma, Psychologist; Dr. Girisha Garg, Ph.D, AI/ML Specialist; and Farheen Islam, Fashion Designer.
        //         </p>
        //         <p>
        //             The Women Advisory Council will provide valuable insights and guidance to Phnk on issues related to women, including features and technology updates. By tapping into the expertise and experiences of these distinguished women, Phnk is demonstrating its commitment to creating a more inclusive and equitable environment for all users.
        //         </p>
        //         <p>
        //             Phnk plans to expand the council to include more diverse viewpoints and voices as it continues to grow and evolve. This approach ensures that the company remains attuned to the needs and concerns of women from various backgrounds and walks of life.
        //         </p>
        //         <p>
        //             Phnk’s Women Advisory Council is a laudable initiative that underscores the company’s dedication to promoting gender equality and empowering women. By seeking input and advice from accomplished women in various fields, Phnk is poised to make a positive impact on the lives of women and contribute to a more equitable society.
        //         </p>
        //     </>)
        // }
    }
    return (
        <div className='about'>
            <Helmet>
                    <title>About Phnk</title>
                </Helmet>
            <div className='about-nav'>
                <div className={`${screen === 0 ? 'bold-700' : ''}`} onClick={() => { setScreen(0) }}>About Phnk</div>
                <div className={`${screen === 1 ? 'bold-700' : ''}`} onClick={() => { setScreen(1) }}>Team</div>
                {/* <div className={`${screen === 2 ? 'bold-700' : ''}`} onClick={() => { setScreen(2) }}>Advisory Council</div> */}
            </div>
            <div className='about-us-content'>
                <Screen />
            </div>
        </div>
    )
}

export default AboutUs


/**
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSea8fUQ5lP7daSWz0cxzX48NzOt8GqkkkXYlQ0F4_UVS7ZRtg/viewform?embedded=true" width="640" height="1693" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
 */