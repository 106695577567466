import React from 'react'
import { Button } from '@mui/material'

const Ideathon = () => {
  return (
    <div className='ideathon'>
      <h1>Join the Phnk Ideathon 2023</h1>
      <p>The theme for Phnk Ideathon 2023 is how women can use Phnk App to achieve financial freedom. Teams have to come up with ideas and business plan that dream up ways in which women use Phnk App to create earning opportunities.</p>
      <h3>Who is eligible to participate?</h3>
      <p>Girl students in college or 1 year out of college.</p>
      <h3>
        Team formation
      </h3>
      <p>Girls can form team of minimum 2 and maximum 4.</p>
      <h3>
        Prize money
      </h3>
      <p>The winning team gets Rs. 50,000, runner up 1 gets Rs. 25,000 and second runner up Rs. 15,000.</p>
      <h3>
        Important Dates
      </h3>
      <p>
        Application Submission Ends: 31st August 2023<br />
        Idea Submission: 15th September 2023<br />
        Teams Shortlisted: 22nd September 2023<br />
        Presentation: 30th September to 7th October 2023<br />
        Results: 7th October 2023<br />
      </p>
      <h3>
        More details
      </h3>
      <Button
        variant='contained'
        style={{
          margin:'10px 0'
        }}
      >Apply</Button>
    </div>
  )
}

export default Ideathon